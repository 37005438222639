class Box {
	constructor(id="", x=0, y=0, width=0, height=0){
		this.id = id;
		this.x = x;
		this.y = y;
		this.width = width;
		this.height = height;
	}
}

export default Box;
