import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitleClose from './elements/DialogTitleClose';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

class RecordsList extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			open: false
		}
		this.Show = this.Show.bind(this);
		this.handleClose = this.handleClose.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
	}

	Show() {
		// on show reset selection
		this.setState({
			open: true,
		})
	}

	handleClose() {
		this.setState({open: false});
	}

    deleteRecord(index) {
        if(!!!this.props.records || (Array.isArray(this.props.records) && this.props.records.length < 2)){
            this.handleClose();
        }

        if(typeof this.props.deleteRecord === "function"){
            this.props.deleteRecord(index);
        }
    }

	render() {
		return (
			<Dialog maxWidth="lg" fullWidth={false} open={this.state.open} onClose={this.handleClose} aria-labelledby="responsive-dialog-title">
				<DialogTitleClose id="responsive-dialog-title" onClick={this.handleClose}>Records</DialogTitleClose>
				<DialogContent dividers>
					<Box sx={{display: 'flex', flexDirection: "row", flexWrap: 'wrap', gap: 2}}>
						{Array.isArray(this.props.records) ? this.props.records.map((item, index) => {
                            return(
                                <Box sx={{display: 'flex', flexDirection: "column"}} key={`${item.download}-${item.href}-${index}`}>
                                    <Link sx={{boxSizing: "content-box", textDecoration: "none"}} href={item.href} download={item.download} about={item.download}>
                                        <video style={{height: 120}} src={item.href} />
                                    </Link>
                                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2, flexWrap: "wrap"}}>
                                        <Link sx={{boxSizing: "content-box", textDecoration: "none"}} href={item.href} download={item.download} about={item.download}>
                                            <Typography sx={{color: "black", fontSize: 13, mb: 0}}  variant="h6" gutterBottom component="div">
                                                {item.download}
                                            </Typography>
                                        </Link>
                                        <Button onClick={()=> this.deleteRecord(index)} variant="text" sx={{color: "red", fontSize: 13}}>delete</Button>
                                    </Box>
                                </Box>
                            )
                        }) : null}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} variant="contained" color="primary" autoFocus>Cancel</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

RecordsList.defaultProps = {
	records: null,
    deleteRecord: null
}

RecordsList.propTypes = {
	records: PropTypes.instanceOf(Array({
        href: PropTypes.string,
        download: PropTypes.string
    })),
    deleteRecord: PropTypes.func
};

export default RecordsList;