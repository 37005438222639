import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VolleyNet extends Component {

	render() {
		const w = this.props.width;
		const h = this.props.height;
		const hW = w / 2;	// half width
		const hH = h / 2;	// half height
		// top left corner of rect regarding x,y center position
		const tlX = this.props.x - hW;
		const tlY = this.props.y - hH;

		return (
            <rect 
                x={tlX} 
                y={tlY} 
                width={w} 
                height={h} 
                fill="url(#goal-net)" 
                stroke="#777777"
                strokeWidth="4" 
                data-ref={this.props.id} 
            />
		);
	}
}

VolleyNet.defaultProps = {
	id: null,
	x: 0,
	y: 0,
	width: 50,
	height: 2000
}

VolleyNet.propTypes = {
	id: PropTypes.string,
	x: PropTypes.number,
	y: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number
}


export default VolleyNet;